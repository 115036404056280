///////////////////////////////////////////////////////////
// File        : SignOut.jsx
// Description : 

// Imports : 

import React from "react";
import { GoogleLogout } from 'react-google-login';

import "./SignOut.scss";
import { onLogout } from "~/Components/api/google";
import { clientId } from "~/googleClientId";

// Function Definition
function SignOut(props) {
  console.info("SignOut.render");
  return (
    <div className="signIn">
      <GoogleLogout
        clientId={clientId}
        buttonText="Sign out"
        onLogoutSuccess={onLogout}
      />
    </div>
  );
}

// Exports

export default SignOut;

